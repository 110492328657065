import { PuzzleData } from 'src/fractions/puzzle-data';

export const level: PuzzleData = {
  rulers: [
    {
      type: 'line',
      fractions: 3,
      from: 3,
      to: 6,
      text: 'fractionMixed',
      displayLevel: 'itemFractions',
    },
    {
      type: 'line',
      fractions: 2,
      from: 3,
      to: 6,
      text: 'fractionMixed',
      displayLevel: 'itemFractions',
    },
  ],
  entities: [
    { type: 'duck', ruler: 0, numerator: 9, denominator: 3 },
    { type: 'battery', ruler: 0, numerator: 14, denominator: 3 },
    { type: 'portal', ruler: 0, numerator: 12, denominator: 3 },
    { type: 'portal', ruler: 1, numerator: 11, denominator: 2 },
    { type: 'vehicle', ruler: 1, numerator: 7, denominator: 2 },
  ],
  actions: [
    { type: 'move', numerator: 5, denominator: 3, text: 'fractionMixed' },
    { type: 'move', numerator: -2, denominator: 3, text: 'fractionMixed' },
    { type: 'move', numerator: -4, denominator: 2, text: 'fractionMixed' },
    { type: 'move', numerator: 5, denominator: 5, text: 'fractionMixed' },
  ],
};
