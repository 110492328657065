import { PuzzleGenerationParametrizerProps } from 'src/fractions/puzzle-data-generator';

export const level: PuzzleGenerationParametrizerProps = {
  fractions: [4, 8],
  rulerCount: [1],
  rulers: [
    {
      type: 'line',
      from: [0],
      to: [1],
      text: ['decimal'],
      displayLevel: ['allParts'],
    },
    {
      type: 'segment',
      text: ['decimal'],
      displayLevel: ['allParts'],
    },
    { type: 'circle', displayLevel: ['default'] },
  ],
  batteries: [0, 1],
  startAtZero: [true],
  negativeJumps: [true, false],
  intermediateJumps: [0, 1],
  superfluousJumps: [1],
  allowedJumps: ['unit', 'regular', 'improper'],
  slots: [],
};
