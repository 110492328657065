import { PuzzleGenerationParametrizerProps } from 'src/fractions/puzzle-data-generator';

export const level: PuzzleGenerationParametrizerProps = {
  fractions: [2, 3, 4],
  rulerCount: [1],
  rulers: [
    { type: 'line', from: [0], to: [2], text: ['fractionMixed'], displayLevel: ['allParts']},
  ],
  batteries: [2],
  startAtZero: [false],
  negativeJumps: [false],
  intermediateJumps: [1, 2],
  superfluousJumps: [1],
  allowedJumps: ['unit'],
  // reduceJumps: [false],
  slots: [3, 4],
};