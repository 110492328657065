export const level = {
  rulers: [{ type: 'segment', text: 'decimal', fractions: 3, displayLevel: 'allParts' }],
  entities: [
    { type: 'duck', ruler: 0, numerator: 2, denominator: 3 },
    { type: 'vehicle', ruler: 0, numerator: 1, denominator: 3 },
  ],
  actions: [
    { type: 'move', numerator: 1, denominator: 3 },
    { type: 'move', numerator: -1, denominator: 3 },
  ],
};
