import { PuzzleData } from 'src/fractions/puzzle-data';

export const level: PuzzleData = {
  rulers: [
    {
      type: 'line',
      fractions: 5,
      from: 0,
      to: 3,
      text: 'fractionMixed',
      displayLevel: 'empty',
    },
    {
      type: 'line',
      fractions: 3,
      from: 0,
      to: 3,
      text: 'fractionMixed',
      displayLevel: 'empty',
    },
  ],
  entities: [
    { type: 'duck', ruler: 0, numerator: 0, denominator: 5 },
    { type: 'portal', ruler: 0, numerator: 9, denominator: 5 },
    { type: 'portal', ruler: 1, numerator: 8, denominator: 3 },
    { type: 'vehicle', ruler: 1, numerator: 9, denominator: 3 },
  ],
  actions: [
    {
      type: 'move',
      numerator: 9,
      denominator: 5,
      text: 'fraction',
    },
    {
      type: 'move',
      numerator: 1,
      denominator: 3,
      text: 'fraction',
    },
  ],
};
