import { PlaySessionData } from 'src/fractions/puzzle-session-data';

export const sessionData: PlaySessionData = {
  id: 'over-one',
  rules: [
    {
      type: 'skip',
      chain: 2,
      tries: 3,
    },
  ],
  sections: [
    {
      levels: ['over-one-level-01'],
    },
    {
      levels: ['over-one-procedural-01'],
    },
    {
      levels: ['over-one-level-02'],
    },
    {
      levels: ['over-one-procedural-02'],
    },
    {
      levels: ['over-one-level-03'],
    },
    {
      levels: ['over-one-procedural-03'],
    },
    {
      levels: ['over-one-level-04'],
    },
    {
      levels: ['over-one-procedural-04'],
    },
    {
      levels: ['over-one-level-05'],
    },
    {
      levels: ['over-one-procedural-05'],
    },
    {
      levels: ['over-one-procedural-06'],
      rules: [],
    },
  ],
};