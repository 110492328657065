export const level = {
  rulers: [
    {
      type: 'circle',
      fractions: 4,
      angleOffset: 0,
    },
  ],
  entities: [
    { type: 'duck', ruler: 0, numerator: 0, denominator: 4 },
    { type: 'vehicle', ruler: 0, numerator: 1, denominator: 4 },
  ],
  actions: [
    { type: 'move', numerator: 1, denominator: 5 },
    { type: 'move', numerator: 1, denominator: 4 },
    { type: 'move', numerator: 1, denominator: 3 },
  ],
};
