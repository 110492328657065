import { PuzzleGenerationParametrizerProps } from 'src/fractions/puzzle-data-generator';

export const level: PuzzleGenerationParametrizerProps = {
  fractions: [1],
  text: ['fractionMixed'],
  rulerCount: [1],
  rulers: [
    {
      type: 'line',
      from: [0],
      to: [5],
      displayLevel: ['empty'],
    },
  ],
  batteries: [1],
  startAtZero: [true],
  negativeJumps: [false],
  intermediateJumps: [1],
  superfluousJumps: [1],
  allowedJumps: [
    [
      { numerator: 1, denominator: 1 },
      { numerator: 2, denominator: 1 },
      { numerator: 3, denominator: 1 },
      { numerator: 4, denominator: 1 },
      { numerator: 5, denominator: 1 },
      { numerator: 6, denominator: 1 },
      { numerator: 7, denominator: 1 },
      { numerator: 8, denominator: 1 },
      { numerator: 9, denominator: 1 },
      { numerator: 10, denominator: 1 },
      { numerator: 11, denominator: 1 },
    ],
  ],
  slots: [2],
};
