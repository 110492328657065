import { PuzzleGenerationParametrizerProps } from 'src/fractions/puzzle-data-generator';

export const level: PuzzleGenerationParametrizerProps = {
  fractions: [2, 3, 4],
  rulerCount: [2],
  rulers: [
    { type: 'line', from: [3], to: [6], text: ['fractionMixed'], displayLevel: ['itemFractions'] },
    { type: 'line', from: [3], to: [6], text: ['fractionMixed'], displayLevel: ['itemFractions'] },
  ],
  batteries: [1, 2],
  startAtZero: [false],
  negativeJumps: [true],
  intermediateJumps: [0, 1],
  superfluousJumps: [1],
  allowedJumps: ['improper'],
  // reduceJumps: [false],
  slots: [3],
  text: ['fractionMixed'],
};
