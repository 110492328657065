import { PuzzleGenerationParametrizerProps } from 'src/fractions/puzzle-data-generator';

export const level: PuzzleGenerationParametrizerProps = {
  fractions: [8],
  text: ['fraction'],
  rulerCount: [1],
  rulers: [
    {
      type: 'segment',
      text: ['fraction'],
      displayLevel: ['allParts'],
    },

    { type: 'circle', displayLevel: ['default'] },
  ],
  batteries: [1],
  startAtZero: [true],
  negativeJumps: [true, false],
  intermediateJumps: [0],
  superfluousJumps: [1],
  allowedJumps: ['unit', 'regular', 'improper'],
  slots: [],
};
