import { PuzzleData } from 'src/fractions/puzzle-data';

export const level: PuzzleData = {
  rulers: [{ type: 'line', fractions: 5, from: 0, to: 1, text: 'fractionMixed', displayLevel: 'allParts' }],
  entities: [
    { type: 'duck', ruler: 0, numerator: 3, denominator: 5 },
    { type: 'vehicle', ruler: 0, numerator: 1, denominator: 5 }
  ],
  actions: [
    { type: 'move', numerator: -2, denominator: 5 },
    { type: 'move', numerator: 2, denominator: 6 },
    { type: 'move', numerator: 1, denominator: 5 },
    { type: 'move', numerator: -2, denominator: 3 }
  ]
};