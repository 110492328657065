import { PuzzleData } from 'src/fractions/puzzle-data';

export const level: PuzzleData = {
  rulers: [
    {
      type: 'segment', 
      text: 'mixed', 
      fractions: 4, from: 0, to: 1, 
      displayLevel: 'allFractions'
    }
  ],
  entities: [
    { type: 'duck', ruler: 0, numerator: 2, denominator: 4 },
    { type: 'vehicle', ruler: 0, numerator: 0, denominator: 4 },
  ],
  actions: [
    { type: 'move', numerator: -1, denominator: 4},
    { type: 'move', numerator: -1, denominator: 4},
    { type: 'move', numerator: 1, denominator: 4},
    { type: 'move', numerator: -1, denominator: 3},
  ]
};
