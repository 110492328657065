import { PuzzleData } from 'src/fractions/puzzle-data';

export const level: PuzzleData = {
  rulers: [
    {
      type: 'line',
      fractions: 3,
      from: 0,
      to: 2,
      text: 'fractionMixed',
      displayLevel: 'itemFractions'
    }
  ],
  entities: [
    { type: 'duck', ruler: 0, numerator: 0, denominator: 3 },
    { type: 'battery', ruler: 0, numerator: 2, denominator: 3 },
    { type: 'vehicle', ruler: 0, numerator: 5, denominator: 3 }
  ],
  actions: [
    { type: 'move', numerator: 2, denominator: 3 },
    { type: 'move', numerator: 3, denominator: 3, text: 'fractionMixed'},
    { type: 'move', numerator: 2, denominator: 4 },
    { type: 'move', numerator: 6, denominator: 3, text: 'fractionMixed'},
  ]
};