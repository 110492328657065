import { PuzzleData } from 'src/fractions/puzzle-data';

export const level: PuzzleData = {
  rulers: [
    {
      type: 'line',
      fractions: 6,
      from: 4,
      to: 5,
      text: 'fractionMixed',
      displayLevel: 'itemFractions',
    },
    {
      type: 'line',
      fractions: 2,
      from: 4,
      to: 5,
      text: 'fractionMixed',
      displayLevel: 'itemFractions',
    },
  ],
  entities: [
    { type: 'duck', ruler: 0, numerator: 24, denominator: 6 },
    { type: 'portal', ruler: 0, numerator: 30, denominator: 6 },
    { type: 'portal', ruler: 1, numerator: 8, denominator: 2 },
    { type: 'battery', ruler: 1, numerator: 9, denominator: 2 },
    { type: 'vehicle', ruler: 1, numerator: 10, denominator: 2 },
  ],
  actions: [
    { type: 'move', numerator: 6, denominator: 6, text: 'fractionMixed' },
    { type: 'move', numerator: 1, denominator: 2, text: 'fractionMixed' },
    { type: 'move', numerator: 1, denominator: 2, text: 'fractionMixed' },
    { type: 'move', numerator: 1, denominator: 3, text: 'fractionMixed' },
  ],
};
