import { PuzzleGenerationParametrizerProps } from 'src/fractions/puzzle-data-generator';

export const level: PuzzleGenerationParametrizerProps = {
  fractions: [3, 6],
  text: ['fractionMixed'],
  rulerCount: [1],
  rulers: [
    {
      type: 'segment',
      text: ['fractionMixed'],
      displayLevel: ['allParts']
    },
    {
      type: 'circle',
      text: ['fractionMixed'],
      displayLevel: ['default']
    },
  ],
  batteries: [0, 1],
  startAtZero: [true],
  negativeJumps: [true],
  intermediateJumps: [0, 1],
  superfluousJumps: [1],
  allowedJumps: [[
    { numerator: 1, denominator: 3 },
    { numerator: 2, denominator: 3 },
    { numerator: 1, denominator: 6 },
    { numerator: 2, denominator: 6 },
    { numerator: 4, denominator: 6 },
    ]],
  slots: [2],
  // reduceJumps: [true]
};