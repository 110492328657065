import { PuzzleGenerationParametrizerProps } from 'src/fractions/puzzle-data-generator';

export const level: PuzzleGenerationParametrizerProps = {
  fractions: [2, 3, 4, 5, 6, 8],
  rulerCount: [1],
  rulers: [
    { type: 'segment', text: ['fractionMixed'], displayLevel: ['allParts'] },
    { type: 'circle', displayLevel: ['default'] },
  ],
  batteries: [1, 2],
  startAtZero: [false],
  negativeJumps: [true],
  intermediateJumps: [0],
  superfluousJumps: [1],
  allowedJumps: ['regular'],
  slots: [2, 3],
};
