export const level = {
  rulers: [{ type: 'circle', fractions: 3, angleOffset: 0 }],
  entities: [
    { type: 'duck', ruler: 0, numerator: 1, denominator: 1 },
    { type: 'vehicle', ruler: 0, numerator: 1, denominator: 3 },
  ],
  actions: [
    { type: 'move', numerator: 1, denominator: 3 },
    { type: 'move', numerator: 1, denominator: 4 },
  ],
};
