import { PuzzleGenerationParametrizerProps } from 'src/fractions/puzzle-data-generator';

export const level: PuzzleGenerationParametrizerProps = {
  fractions: [],
  rulerCount: [1],
  rulers: [
    { type: 'line', from: [0], to: [1], fractions: [3, 4, 5, 6], text: ['fractionMixed'], displayLevel: ['empty']},
  ],
  batteries: [0],
  startAtZero: [true],
  negativeJumps: [false],
  intermediateJumps: [0],
  superfluousJumps: [0],
  allowedJumps: ['regular'],
  // reduceJumps: [false],
  slots: [2],
};