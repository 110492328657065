export const level = {
  rulers: [{ type: 'circle', fractions: 5, angleOffset: 0 }],
  entities: [
    { type: 'duck', ruler: 0, numerator: 4, denominator: 5 },
    { type: 'battery', ruler: 0, numerator: 2, denominator: 5 },
    { type: 'vehicle', ruler: 0, numerator: 3, denominator: 5 },
  ],
  actions: [
    { type: 'move', numerator: 2, denominator: 5 },
    { type: 'move', numerator: 1, denominator: 5 },
    { type: 'move', numerator: 1, denominator: 5 },
    { type: 'move', numerator: 2, denominator: 5 },
  ],
};
