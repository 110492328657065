export const level = {
  rulers: [{ type: 'segment', text: 'decimal', fractions: 5, displayLevel: 'allParts' }],
  entities: [
    { type: 'duck', ruler: 0, numerator: 0, denominator: 5 },
    { type: 'battery', ruler: 0, numerator: 2, denominator: 5 },
    { type: 'vehicle', ruler: 0, numerator: 5, denominator: 5 },
  ],
  actions: [
    { type: 'move', numerator: 3, denominator: 5 },
    { type: 'move', numerator: -1, denominator: 5 },
    { type: 'move', numerator: 3, denominator: 5 },
    { type: 'move', numerator: 1, denominator: 5 },
  ],
};
