import { PlaySessionData } from 'src/fractions/puzzle-session-data';

export const sessionData: PlaySessionData = {
  id: 'numberline',
  rules: [
    {
      type: 'skip',
      chain: 2,
      tries: 3,
    },
  ],
  sections: [
    {
      levels: ['numberline-level-01'],
    },
    {
      levels: ['numberline-procedural-01'],
    },
    {
      levels: ['numberline-level-02'],
    },
    {
      levels: ['numberline-procedural-02'],
    },
    {
      levels: ['numberline-level-03'],
    },
    {
      levels: ['numberline-procedural-03'],
    },
    {
      levels: ['numberline-level-04'],
    },
    {
      levels: ['numberline-procedural-04'],
    },
    {
      levels: ['numberline-level-05'],
    },
    {
      levels: ['numberline-procedural-05'],
    },
    {
      levels: ['numberline-procedural-06'],
      rules: [],
    },
  ],
};