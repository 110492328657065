import { PlaySessionData } from 'src/fractions/puzzle-session-data';

export const sessionData: PlaySessionData = {
  id: 'playtest-1-part-05',
  rules: [
    {
      type: 'skip',
      chain: 2,
      tries: 3,
    },
  ],
  sections: [
    {
      levels: ['playtest-1-procedural-05'],
      rules: [],
    },
  ],
};
