export const level = {
  rulers: [
    { type: 'segment', text: 'decimal', fractions: 4, displayLevel: 'allParts' },
    { type: 'segment', text: 'decimal', fractions: 4, displayLevel: 'allParts' },
  ],
  entities: [
    { type: 'duck', ruler: 0, numerator: 0, denominator: 5 },
    { type: 'battery', ruler: 0, numerator: 1, denominator: 2 },
    { type: 'portal', ruler: 0, numerator: 4, denominator: 4 },
    { type: 'portal', ruler: 1, numerator: 0, denominator: 4 },
    { type: 'vehicle', ruler: 1, numerator: 2, denominator: 4 },
  ],
  actions: [
    { type: 'move', numerator: 1, denominator: 2 },
    { type: 'move', numerator: 1, denominator: 2 },
    { type: 'move', numerator: 1, denominator: 2 },
  ],
};
