import { PuzzleGenerationParametrizerProps } from 'src/fractions/puzzle-data-generator';

export const level: PuzzleGenerationParametrizerProps = {
  fractions: [2],
  text: ['fractionMixed'],
  rulerCount: [2],
  rulers: [
    {
      type: 'line',
      from: [0],
      to: [3],
      displayLevel: ['default'],
    },
    {
      type: 'line',
      from: [0],
      to: [3],
      displayLevel: ['default'],
    },
  ],
  batteries: [1],
  startAtZero: [false],
  negativeJumps: [true],
  intermediateJumps: [1, 2],
  superfluousJumps: [0],
  allowedJumps: [
    [
      { numerator: 1, denominator: 1 },
      { numerator: 2, denominator: 1 },
      { numerator: 1, denominator: 2 },
    ],
  ],
  slots: [3, 4],
};
