import { PuzzleData } from 'src/fractions/puzzle-data';

export const level: PuzzleData = {
  rulers: [
    {
      type: 'line',
      fractions: 2,
      from: 0,
      to: 2,
      text: 'fractionMixed',
      displayLevel: 'itemFractions',
    },
  ],
  entities: [
    { type: 'duck', ruler: 0, numerator: 0, denominator: 2 },
    { type: 'battery', ruler: 0, numerator: 3, denominator: 2 },
    { type: 'vehicle', ruler: 0, numerator: 4, denominator: 2 },
  ],
  actions: [
    {
      type: 'move',
      numerator: 3,
      denominator: 2,
      text: 'fractionMixed',
    },
    {
      type: 'move',
      numerator: 1,
      denominator: 2,
      text: 'fractionMixed',
    },
    {
      type: 'move',
      numerator: 1,
      denominator: 6,
      text: 'fractionMixed',
    },
  ],
};
