import { PlaySessionData } from 'src/fractions/puzzle-session-data';

export const sessionData: PlaySessionData = {
  id: 'solo-whole-numbers-intro-02',
  rules: [
    {
      type: 'skip',
      chain: 2,
      tries: 3,
    },
  ],
  sections: [
    {
      levels: ['solo-whole-numbers-intro-02-level-05'],
    },
    {
      levels: ['solo-whole-numbers-intro-02-procedural-04'],
    },
    {
      levels: ['solo-whole-numbers-intro-02-level-07'],
    },
    {
      levels: ['solo-whole-numbers-intro-02-procedural-05'],
    },
    {
      levels: ['solo-whole-numbers-intro-02-procedural-06'],
      rules: [],
    },
  ],
};
