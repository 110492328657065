import { PuzzleData } from 'src/fractions/puzzle-data';

export const level: PuzzleData = {
  rulers: [
    {
      type: 'line',
      fractions: 6,
      from: 0,
      to: 3,
      text: 'mixed',
      displayLevel: 'empty',
    },
    {
      type: 'line',
      fractions: 3,
      from: 0,
      to: 3,
      text: 'mixed',
      displayLevel: 'empty',
    },
  ],
  entities: [
    { type: 'duck', ruler: 0, numerator: 6, denominator: 6 },
    { type: 'portal', ruler: 0, numerator: 11, denominator: 6 },
    { type: 'portal', ruler: 1, numerator: 0, denominator: 3 },
    { type: 'vehicle', ruler: 1, numerator: 1, denominator: 3 },
  ],
  actions: [
    {
      type: 'move',
      numerator: 5,
      denominator: 6,
      text: 'fractionMixed',
    },
    {
      type: 'move',
      numerator: 1,
      denominator: 3,
      text: 'fractionMixed',
    },
  ],
};
