import { PuzzleGenerationParametrizerProps } from 'src/fractions/puzzle-data-generator';

export const level: PuzzleGenerationParametrizerProps = {
  fractions: [3, 4, 6],
  text: ['fractionMixed'],
  rulerCount: [2],
  rulers: [
    {
      type: 'segment',
      text: ['fractionMixed'],
      displayLevel: ['itemFractions']
    },
    {
      type: 'segment',
      text: ['fractionMixed'],
      displayLevel: ['itemFractions']
    },
  ],
  batteries: [0, 1],
  startAtZero: [false],
  negativeJumps: [true],
  intermediateJumps: [1],
  superfluousJumps: [1],
  allowedJumps: [[
    { numerator: 1, denominator: 2 },
    { numerator: 1, denominator: 3 },
    { numerator: 2, denominator: 3 },
    { numerator: 1, denominator: 4 },
    { numerator: 2, denominator: 4 },
    { numerator: 1, denominator: 6 },
    { numerator: 2, denominator: 6 },
    { numerator: 3, denominator: 6 },
    ]],
  slots: [3],
  // reduceJumps: [true]
};