import { PuzzleGenerationParametrizerProps } from 'src/fractions/puzzle-data-generator';

export const level: PuzzleGenerationParametrizerProps = {
  fractions: [4, 8],
  text: ['fraction'],
  rulerCount: [2],
  rulers: [
    {
      type: 'segment',
      displayLevel: ['allParts'],
    },
    {
      type: 'segment',
      displayLevel: ['allParts'],
    },
  ],
  batteries: [0],
  startAtZero: [true, false],
  negativeJumps: [true, false],
  intermediateJumps: [0],
  superfluousJumps: [1],
  allowedJumps: ['unit', 'regular', 'improper'],
  slots: [],
};
