import { PuzzleData } from 'src/fractions/puzzle-data';

export const level: PuzzleData = {
  rulers: [
    {
      type: 'line',
      fractions: 4,
      from: 0,
      to: 1,
      text: 'mixed',
      displayLevel: 'empty',
    },
  ],
  entities: [
    { type: 'duck', ruler: 0, numerator: 0, denominator: 4 },
    { type: 'battery', ruler: 0, numerator: 3, denominator: 4 },
    { type: 'vehicle', ruler: 0, numerator: 4, denominator: 4 },
  ],
  actions: [
    {
      type: 'move',
      numerator: 3,
      denominator: 4,
      text: 'fraction',
    },
    {
      type: 'move',
      numerator: 1,
      denominator: 4,
      text: 'fraction',
    },
  ],
};
