import { PlaySessionData } from 'src/fractions/puzzle-session-data';

export const sessionData: PlaySessionData = {
  id: 'solo-hardcore-01',
  rules: [
    {
      type: 'skip',
      chain: 2,
      tries: 3,
    },
  ],
  sections: [
    {
      levels: ['solo-hardcore-01-level-01'],
    },
    {
      levels: ['solo-hardcore-01-procedural-01'],
    },
    {
      levels: ['solo-hardcore-01-level-02'],
    },
    {
      levels: ['solo-hardcore-01-procedural-02'],
    },
    {
      levels: ['solo-hardcore-01-level-03'],
    },
    {
      levels: ['solo-hardcore-01-procedural-03'],
      rules: [],
    },
  ],
};
