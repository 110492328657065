import { PuzzleData } from 'src/fractions/puzzle-data';

export const level: PuzzleData = {
  rulers: [
    {
      type: 'line',
      fractions: 2,
      from: 0,
      to: 3,
      text: 'fractionMixed',
      displayLevel: 'default'
    },
    {
      type: 'line',
      fractions: 2,
      from: 0,
      to: 3,
      text: 'fractionMixed',
      displayLevel: 'default'
    }
  ],
  entities: [
    { type: 'duck', ruler: 0, numerator: 0, denominator: 2 },
    { type: 'portal', ruler: 0, numerator: 4, denominator: 2 },
    { type: 'portal', ruler: 1, numerator: 0, denominator: 2 },
    { type: 'vehicle', ruler: 1, numerator: 5, denominator: 2 }
  ],
  actions: [
    {
      type: 'move',
      numerator: 2,
      denominator: 1,
      text: 'fractionMixed'
    },
    {
      type: 'move',
      numerator: 2,
      denominator: 1,
      text: 'fractionMixed'
    },
    {
      type: 'move',
      numerator: 1,
      denominator: 2,
      text: 'fractionMixed'
    },
  ]
};