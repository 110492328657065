import { PuzzleGenerationParametrizerProps } from 'src/fractions/puzzle-data-generator';

export const level: PuzzleGenerationParametrizerProps = {
  fractions: [2, 3, 4, 5],
  rulerCount: [1],
  rulers: [{ type: 'line', from: [0], to: [2], text: ['fractionMixed'], displayLevel: ['itemFractions'] }],
  batteries: [0, 1],
  startAtZero: [true],
  negativeJumps: [true],
  intermediateJumps: [0, 1],
  superfluousJumps: [1],
  allowedJumps: ['improper'],
  // reduceJumps: [false],
  slots: [2, 3],
  text: ['fractionMixed'],
};
