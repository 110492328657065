import { PuzzleGenerationParametrizerProps } from 'src/fractions/puzzle-data-generator';

export const level: PuzzleGenerationParametrizerProps = {
  fractions: [3, 4],
  rulerCount: [2],
  rulers: [
    { type: 'line', from: [3,4], to: [5, 6], text: ['fractionMixed'], displayLevel: ['itemFractions']},
    { type: 'circle', displayLevel:['default']},
    { type: 'circle', displayLevel:['default']}
  ],
  batteries: [2, 3],
  startAtZero: [false],
  negativeJumps: [true],
  intermediateJumps: [0, 1],
  superfluousJumps: [1],
  allowedJumps: ['improper'],
  // reduceJumps: [false],
  slots: [4],
  text: ['fractionMixed']
};