import { PuzzleData } from 'src/fractions/puzzle-data';

export const level: PuzzleData = {
  rulers: [
    {
      type: 'line',
      fractions: 3,
      from: 0,
      to: 1,
      text: 'fractionMixed',
      displayLevel: 'allParts'
    }
  ],
  entities: [
    { type: 'duck', ruler: 0, numerator: 1, denominator: 3 },
    { type: 'battery', ruler: 0, numerator: 0, denominator: 3 },
    { type: 'vehicle', ruler: 0, numerator: 2, denominator: 3 }
  ],
  actions: [
    { type: 'move', numerator: -1, denominator: 3 },
    { type: 'move', numerator: 2, denominator: 3 },
    { type: 'move', numerator: 2, denominator: 4 },
    { type: 'move', numerator: 1, denominator: 3 },
  ]
};