import { PuzzleGenerationParametrizerProps } from 'src/fractions/puzzle-data-generator';

export const level: PuzzleGenerationParametrizerProps = {
  fractions: [2, 3, 4, 5, 6],
  rulerCount: [1],
  rulers: [{ type: 'line', from: [0], to: [1], text: ['fractionMixed'], displayLevel: ['itemFractions'] }],
  batteries: [0, 1],
  startAtZero: [true],
  negativeJumps: [false],
  intermediateJumps: [0, 1],
  superfluousJumps: [1],
  allowedJumps: ['regular'],
  // reduceJumps: [false],
  slots: [2],
};
