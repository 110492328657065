import { PuzzleData } from 'src/fractions/puzzle-data';

export const level: PuzzleData = {
  rulers: [
    {
      type: 'line',
      fractions: 5,
      from: 0,
      to: 1,
      text: 'fractionMixed',
      displayLevel: 'itemFractions',
    },
  ],
  entities: [
    { type: 'duck', ruler: 0, numerator: 0, denominator: 5 },
    { type: 'vehicle', ruler: 0, numerator: 4, denominator: 5 },
  ],
  actions: [
    { type: 'move', numerator: 2, denominator: 5 },
    { type: 'move', numerator: 2, denominator: 5 },
    { type: 'move', numerator: 1, denominator: 4 },
    { type: 'move', numerator: 2, denominator: 3 },
  ],
};
