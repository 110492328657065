import { PuzzleData } from 'src/fractions/puzzle-data';

export const level: PuzzleData = {
  rulers: [
    {
      type: 'segment',
      text: 'fraction',
      fractions: 4,
      from: 0,
      to: 1,
      displayLevel: 'allParts',
    },
  ],
  entities: [
    { type: 'duck', ruler: 0, numerator: 0, denominator: 4 },
    { type: 'vehicle', ruler: 0, numerator: 3, denominator: 4 },
  ],
  actions: [
    { type: 'move', numerator: 3, denominator: 2 },
    { type: 'move', numerator: 3, denominator: 4 },
    { type: 'move', numerator: -5, denominator: 2 },
    { type: 'move', numerator: 1, denominator: 5 },
  ],
};
