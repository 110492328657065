export const level = {
  rulers: [{ type: 'line', text: 'fraction', fractions: 3, from: 0, to: 1, displayLevel: 'default' }],
  entities: [
    { type: 'duck', ruler: 0, numerator: 0, denominator: 2 },

    { type: 'vehicle', ruler: 0, numerator: 2, denominator: 3 },
  ],
  actions: [
    { type: 'move', numerator: 1, denominator: 3 },
    { type: 'move', numerator: 1, denominator: 3 },
    { type: 'move', numerator: 1, denominator: 2 },
    { type: 'move', numerator: 1, denominator: 4 },
  ],
};
