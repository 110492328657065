import { PuzzleData } from 'src/fractions/puzzle-data';

export const level: PuzzleData = {
  rulers: [
    {
      type: 'segment', 
      text: 'mixed', 
      fractions: 2, from: 0, to: 1, 
      displayLevel: 'allParts'
    }
  ],
  entities: [
    { type: 'duck', ruler: 0, numerator: 0, denominator: 2 },
    { type: 'vehicle', ruler: 0, numerator: 1, denominator: 2 },
  ],
  actions: [
    { type: 'move', numerator: 1, denominator: 3},
    { type: 'move', numerator: 1, denominator: 4 },
    { type: 'move', numerator: 1, denominator: 2 },
  ]
};