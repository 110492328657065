import { PuzzleData, isPuzzleData } from './puzzle-data';
import { PlaySessionData } from './puzzle-session-data';
import {
  PuzzleGenerationParametrizerProps,
  PuzzleGenerationProps,
  isProceduralLevel,
  isProceduralRamdomizeLevel,
} from './puzzle-data-generator';
import { camelToKebab } from '../core/case-transformer';

import * as specimenBundle from './data/levels/specimen';
import * as demo from './data/levels/demo';
import * as evaluation from './data/levels/evaluation';
import * as intro from './data/levels/introduction';
import * as mediumBundle from './data/levels/medium';
import * as advanced from './data/levels/advanced';
import * as overOne from './data/sessions/over-one';
import * as numberline from './data/sessions/numberline';
import * as discovering from './data/sessions/discovering-fractions';
import * as comparing from './data/sessions/comparing-fractions';
import * as procedurals from './data/procedurals';
import * as equivalent from './data/sessions/equivalent-fractions';
import * as hardcore from './data/sessions/hardcore';
import * as playtest1 from './data/sessions/playtest-1';
import * as wholeNumbersIntro from './data/sessions/whole-numbers-intro';
import * as soloDiscover01 from './data/sessions/solo/discovering-fractions-01';
import * as soloDiscover02 from './data/sessions/solo/discovering-fractions-02';
import * as soloComparingFractions01 from './data/sessions/solo/comparing-fractions-01';
import * as soloComparingFractions02 from './data/sessions/solo/comparing-fractions-02';
import * as soloHardCore01 from './data/sessions/solo/hardcore-01';
import * as soloHardCore02 from './data/sessions/solo/hardcore-02';
import * as soloOverOne01 from './data/sessions/solo/over-one-01';
import * as soloOverOne02 from './data/sessions/solo/over-one-02';
import * as soloWholeNumbersIntro01 from './data/sessions/solo/whole-numbers-intro-01';
import * as soloWholeNumbersIntro02 from './data/sessions/solo/whole-numbers-intro-02';

// TODO: this is a temporary solution to the problem of loading levels
// TODO: this is a bit rough, as it requires always loading all existing level
// TODO: a better solution would be to have level bundles that can be loaded on demand (asynchronously)
// TODO: a better solution is that each module defines an index.ts that re-exports the required levels
export const levelBundle: Map<string, PuzzleData> = new Map();
export const sessionBundle: Map<string, PlaySessionData> = new Map();
export const levelProceduralBundle: Map<string, PuzzleGenerationProps> = new Map();
export const levelProceduralRandomizeBundle: Map<string, PuzzleGenerationParametrizerProps> = new Map();

register(specimenBundle, 'specimen');
register(demo, 'demo');
register(evaluation, 'eval');
register(intro, 'intro');
register(mediumBundle, 'medium');
register(advanced, 'advanced');
register(overOne, 'over-one');
register(numberline, 'numberline');
register(discovering, 'discovering-fractions');
register(comparing, 'comparing-fractions');
register(equivalent, 'equivalent-fractions');
register(hardcore, 'hardcore');
register(procedurals);
register(wholeNumbersIntro, 'whole-numbers-intro');
register(playtest1, 'playtest-1');
register(soloComparingFractions01, 'solo-comparing-fractions-01');
register(soloComparingFractions02, 'solo-comparing-fractions-02');
register(soloDiscover01, 'solo-discovering-fractions-01');
register(soloDiscover02, 'solo-discovering-fractions-02');
register(soloHardCore01, 'solo-hardcore-01');
register(soloHardCore02, 'solo-hardcore-02');
register(soloOverOne01, 'solo-over-one-01');
register(soloOverOne02, 'solo-over-one-02');
register(soloWholeNumbersIntro01, 'solo-whole-numbers-intro-01');
register(soloWholeNumbersIntro02, 'solo-whole-numbers-intro-02');

export function parseLevelResource(resource: string) {
  if (levelBundle.has(resource)) return [levelBundle.get(resource)!];
  if (levelProceduralBundle.has(resource)) return levelProceduralBundle.get(resource)!;
  if (levelProceduralRandomizeBundle.has(resource)) return levelProceduralRandomizeBundle.get(resource)!;
  // try parsing all level keys
  const levels = [];
  for (const levelKey of levelBundle.entries()) {
    if (levelKey[0].indexOf(resource) >= 0) levels.push(levelKey[1]);
  }
  return levels;
}

function register(obj: Record<string, any>, prefix: string = '') {
  prefix = prefix ? prefix + '-' : '';
  for (const [key, value] of Object.entries(obj)) {
    const kebabKey = prefix + camelToKebab(key);
    if (isPuzzleData(value)) {
      levelBundle.set(kebabKey, value);
    } else if (isProceduralLevel(value)) {
      levelProceduralBundle.set(kebabKey, value);
    } else if (isProceduralRamdomizeLevel(value)) {
      levelProceduralRandomizeBundle.set(kebabKey, value);
    } else {
      // play session // TODO: we should have a better way to identify play sessions
      sessionBundle.set(kebabKey, value);
    }
  }
}
