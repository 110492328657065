import { PlaySessionData } from 'src/fractions/puzzle-session-data';

export const sessionData: PlaySessionData = {
  id: 'whole-numbers-intro',
  rules: [
    {
      type: 'skip',
      chain: 2,
      tries: 3,
    },
  ],
  sections: [
    {
      levels: ['whole-numbers-intro-level-01'],
    },
    {
      levels: ['whole-numbers-intro-level-02'],
    },
    {
      levels: ['whole-numbers-intro-procedural-01'],
    },
    {
      levels: ['whole-numbers-intro-level-03'],
    },
    {
      levels: ['whole-numbers-intro-procedural-02'],
    },
    {
      levels: ['whole-numbers-intro-level-04'],
    },
    {
      levels: ['whole-numbers-intro-procedural-03'],
    },
    {
      levels: ['whole-numbers-intro-level-05'],
    },
    {
      levels: ['whole-numbers-intro-level-06'],
    },
    {
      levels: ['whole-numbers-intro-procedural-04'],
    },
    {
      levels: ['whole-numbers-intro-level-07'],
    },
    {
      levels: ['whole-numbers-intro-procedural-05'],
    },
    {
      levels: ['whole-numbers-intro-procedural-06'],
      rules: [],
    },
  ],
};
