import { PlaySessionData } from 'src/fractions/puzzle-session-data';

export const sessionData: PlaySessionData = {
  id: 'playtest-1',
  rules: [
    {
      type: 'skip',
      chain: 2,
      tries: 3,
    },
  ],
  sections: [
    {
      levels: ['playtest-1-procedural-01'],
    },
    {
      levels: ['playtest-1-procedural-02'],
    },
    {
      levels: ['playtest-1-procedural-03'],
    },
    {
      levels: ['playtest-1-procedural-04'],
    },
    {
      levels: ['playtest-1-procedural-05'],
      rules: [],
    },
  ],
};
