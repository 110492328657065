export const level = {
  rulers: [{ type: 'line', text: 'decimal', fractions: 2, from: 0, to: 1 }],
  entities: [
    { type: 'duck', ruler: 0, numerator: 0, denominator: 4 },
    { type: 'vehicle', ruler: 0, numerator: 2, denominator: 2 },
  ],
  actions: [
    { type: 'move', numerator: 1, denominator: 2 },
    { type: 'move', numerator: 1, denominator: 2 },
  ],
};
