import { PuzzleData } from 'src/fractions/puzzle-data';

export const level: PuzzleData = {
  rulers: [
    {
      type: 'segment',
      fractions: 6,
      from: 0,
      to: 1,
      text: 'fractionMixed',
      displayLevel: 'allParts'
    }
  ],
  entities: [
    { type: 'duck', ruler: 0, numerator: 0, denominator: 6 },
    { type: 'battery', ruler: 0, numerator: 2, denominator: 6 },
    { type: 'battery', ruler: 0, numerator: 4, denominator: 6 },
    { type: 'vehicle', ruler: 0, numerator: 6, denominator: 6 }
  ],
  actions: [
    {
      type: 'move',
      numerator: 2,
      denominator: 6,
      text: 'fractionMixed'
    },
    {
      type: 'move',
      numerator: 2,
      denominator: 6,
      text: 'fractionMixed'
    },
    {
      type: 'move',
      numerator: 2,
      denominator: 6,
      text: 'fractionMixed'
    },
    {
      type: 'move',
      numerator: 1,
      denominator: 4,
      text: 'fractionMixed'
    }
  ]
};