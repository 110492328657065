import { PuzzleData } from 'src/fractions/puzzle-data';

export const level: PuzzleData = {
  rulers: [
    {
      type: 'line',
      fractions: 1,
      from: 0,
      to: 5,
      text: 'fractionMixed',
      displayLevel: 'empty',
    },
  ],
  entities: [
    { type: 'duck', ruler: 0, numerator: 4, denominator: 1 },
    { type: 'vehicle', ruler: 0, numerator: 0, denominator: 1 },
  ],
  actions: [
    {
      type: 'move',
      numerator: -1,
      denominator: 1,
      text: 'fractionMixed',
    },
    {
      type: 'move',
      numerator: -3,
      denominator: 1,
      text: 'fractionMixed',
    },
    {
      type: 'move',
      numerator: 4,
      denominator: 1,
      text: 'fractionMixed',
    },
  ],
};
