import { PuzzleGenerationProps } from '../../puzzle-data-generator';

export const easy: PuzzleGenerationProps = {
  rulers: [{ type: 'line', fractions: 4, from: 0, to: 1, text: 'fraction' }],
  batteries: 1,
  startAtZero: true,
  negativeJumps: true,
  intermediateJumps: 0,
  superfluousJumps: 0,
  allowedJumps: [],
  // reduceJumps: false,
};
export const medium: PuzzleGenerationProps = {
  rulers: [
    { type: 'line', fractions: 4, from: 0, to: 2, text: 'fraction' },
    { type: 'line', fractions: 3, from: 0, to: 1, text: 'fraction' },
  ],
  batteries: 2,
  startAtZero: true,
  negativeJumps: true,
  intermediateJumps: 0,
  superfluousJumps: 0,
  allowedJumps: [],
  // reduceJumps: false,
};
export const hard: PuzzleGenerationProps = {
  rulers: [
    { type: 'line', fractions: 4, from: 0, to: 2, text: 'fraction' },
    { type: 'line', fractions: 3, from: 0, to: 2, text: 'fraction' },
  ],
  batteries: 3,
  startAtZero: false,
  negativeJumps: true,
  intermediateJumps: 0,
  superfluousJumps: 0,
  allowedJumps: [],
  // reduceJumps: false,
};
