import { PuzzleData } from 'src/fractions/puzzle-data';

export const level: PuzzleData = {
  rulers: [
    {
      type: 'segment',
      fractions: 3,
      from: 0,
      to: 1,
      text: 'mixed',
      displayLevel: 'allParts',
    },
  ],
  entities: [
    { type: 'duck', ruler: 0, numerator: 0, denominator: 3 },
    { type: 'battery', ruler: 0, numerator: 1, denominator: 3 },
    { type: 'vehicle', ruler: 0, numerator: 3, denominator: 3 },
  ],
  actions: [
    {
      type: 'move',
      numerator: 1,
      denominator: 3,
      text: 'fraction',
    },
    {
      type: 'move',
      numerator: 2,
      denominator: 3,
      text: 'fraction',
    },
    {
      type: 'move',
      numerator: 1,
      denominator: 6,
      text: 'fraction',
    },
  ],
};
