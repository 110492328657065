import { PuzzleGenerationParametrizerProps } from 'src/fractions/puzzle-data-generator';

export const level: PuzzleGenerationParametrizerProps = {
  fractions: [],
  rulerCount: [2],
  rulers: [
    { type: 'segment', fractions: [2, 3, 4], text: ['fractionMixed'], displayLevel: ['empty']},
    { type: 'segment', fractions: [5, 6, 8], text: ['fractionMixed'], displayLevel: ['empty']}
  ],
  batteries: [0],
  startAtZero: [true],
  negativeJumps: [false],
  intermediateJumps: [0],
  superfluousJumps: [0],
  allowedJumps: ['regular'],
  // reduceJumps: [false],
  slots: [2],
};