export function camelToSnake(str: string): string {
  return str.replace(/[A-Z]/g, (match) => `_${match.toLowerCase()}`);
}

export function snakeToCamel(str: string): string {
  return str.replace(/_\w/g, (match) => match[1].toUpperCase());
}

export function camelToKebab(str: string): string {
  return str.replace(/[\dA-Z]+(?![a-z])|[A-Z]/g, (match, offset) => {
    if (offset === 0) {
      return match.toLowerCase();
    }
    return `-${match.toLowerCase()}`;
  });
}

export function kebabToCamel(str: string): string {
  return str.replace(/-\w/g, (match) => match[1].toUpperCase());
}

export function camelToPascal(str: string): string {
  return str.replace(/^[a-z]/, (match) => match.toUpperCase());
}

export function pascalToCamel(str: string): string {
  return str.replace(/^[A-Z]/, (match) => match.toLowerCase());
}
