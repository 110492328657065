import { PuzzleGenerationParametrizerProps } from 'src/fractions/puzzle-data-generator';

export const level: PuzzleGenerationParametrizerProps = {
  fractions: [2, 3, 4],
  rulerCount: [2],
  rulers: [
    { type: 'line', from: [0], to: [3], fractions: [2, 3, 4], text: ['fractionMixed'], displayLevel: ['allParts'] },
    { type: 'line', from: [0], to: [3], fractions: [5, 6, 7], text: ['fractionMixed'], displayLevel: ['allParts'] },
    { type: 'circle', fractions: [5, 6, 7], text: ['fractionMixed'], displayLevel: ['allParts'] },
  ],
  batteries: [1, 2],
  startAtZero: [true],
  negativeJumps: [false],
  intermediateJumps: [0],
  superfluousJumps: [0],
  allowedJumps: ['improper'],
  // reduceJumps: [false],
  slots: [3],
  text: ['fractionMixed'],
};
