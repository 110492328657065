import { PuzzleGenerationParametrizerProps } from 'src/fractions/puzzle-data-generator';

export const level: PuzzleGenerationParametrizerProps = {
  fractions: [2, 3, 4, 5, 6],
  rulerCount: [2],
  rulers: [
    { type: 'line', from: [0], to: [2], text: ['fractionMixed'], displayLevel: ['itemFractions']},
    { type: 'line', from: [0], to: [2], text: ['fractionMixed'], displayLevel: ['itemFractions']},
    {type: 'circle', displayLevel:['default']}
  ],
  batteries: [1, 2],
  startAtZero: [false],
  negativeJumps: [true],
  intermediateJumps: [0, 1],
  superfluousJumps: [1],
  allowedJumps: ['improper'],
  // reduceJumps: [false],
  slots: [3],
  text: ['fractionMixed']
};