import { PuzzleGenerationParametrizerProps } from 'src/fractions/puzzle-data-generator';

export const level: PuzzleGenerationParametrizerProps = {
  fractions: [2, 3, 4],
  rulerCount: [1],
  rulers: [
    { type: 'segment', text: ['fractionMixed'], displayLevel: ['allParts']},
    { type: 'circle', displayLevel:['default'] },
  ],
  batteries: [0],
  startAtZero: [true],
  negativeJumps: [false],
  intermediateJumps: [0],
  superfluousJumps: [3],
  allowedJumps: ['unit'],
  // reduceJumps: [false],
  slots: [1],
};